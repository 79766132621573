<script>
import ChartistProfitCustomer from "@/components/ChartistSumUnits.vue";


/**
 * Revenue Analytics component
 */
export default  {
  components: {ChartistProfitCustomer},
  methods: {
    changeTypeDate(t) {
      this.typeDate = t
    },
  },
  mounted() {
    this.change_header_data({countOrders:7,countCustomers:7,totalProfit:7})
  },
  props: {
    keyApi: {default:"get_orders_sum_units"},
  },
  data() {
    return {
      typeDate: "monthly",
    };
  },


};
</script>

<template>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="float-right d-none d-md-inline-block">
            <div class="btn-group mb-2">
              <button @click="changeTypeDate('daly')" type="button" class="btn btn-sm btn-light">daily</button>
              <button @click="changeTypeDate('weekly')" type="button" class="btn btn-sm btn-light ">Weekly</button>
              <button @click="changeTypeDate('monthly')" type="button" class="btn btn-sm btn-light">Monthly</button>
            </div>
          </div>
          <h4 class="card-title mb-4">Sum Unit</h4>

          <!-- Line Chart -->
          <ChartistProfitCustomer :key-api="this.keyApi"  :typeDate="typeDate" :height="300"  />
        </div>
      </div>
    </div>
  </div>

</template>