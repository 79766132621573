<script>
import Layout from "../../layouts/main";
import {GetDataMethods, notificationMethods} from "@/state/helpers";
import PricingAnalytics from "@/views/pages/orders/pricingAnalytics.vue";

/**
 * Orders Component
 */
export default {
  components: {
    PricingAnalytics,
    Layout,
  },
  data() {
    return {}

  },
  methods: {
    ...GetDataMethods,
    ...notificationMethods,
    getDataOrdersUnits() {
      this.getData("get_count_orders_pricing").then((res) => {
        this.labelsIds = res.data.data.count_orders.labels
        this.dataIds = res.data.data.count_orders.data

      })
    },
    getDataOrdersMessages() {
      this.getData("get_count_orders_messages").then((res) => {
        this.labelsMessages = res.data.data.count_orders.labels
        this.dataMessages = res.data.data.count_orders.data
        this.messagT = res.data.data.message_t
        this.messagTCountOrders = res.data.data.message_t_count_orders
        this.v = false
        this.barChart.series = [{
          data: res.data.data.count_orders.data
        }]
        this.barChart.chartOptions.xaxis.categories = res.data.data.count_orders.labels

        this.v = true
      })
    },


  },
  mounted() {
    this.getDataOrdersUnits()
    this.getDataOrdersMessages()
  }

};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-xl-12">
        <PricingAnalytics keyApi="get_orders_sum_Money" class="h-auto"/>
      </div>

    </div>


  </Layout>
</template>