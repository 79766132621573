<script>
import {Line} from "vue-chartjs";
import {
  notificationMethods, GetDataMethods
} from "@/state/helpers";

export default {
  props: {
    typeDate: {type: String, default: "monthly"},
    keyApi:{default: "get_orders_sum_units"}
  },
  watch: {

    typeDate: function () { // watch it
      this.getData1()
    },
  },
  extends: Line,
  methods: {
    ...GetDataMethods,
    ...notificationMethods,
    getData1() {
      this.getData(this.keyApi +"/" + this.typeDate).then((res) => {
        var data = res.data.data

        this.changeRender(data.data_line_orders.labels, data.data_line_orders.data, data.min_length, data.max_length)
      })
    },
    changeRender(labels, data, min, max) {
      this.renderChart(
          {
            labels: labels,
            datasets: [
              {
                label: "Sum Units",
                fill: true,
                lineTension: 0.5,
                backgroundColor: "rgba(85, 110, 230, 0.2)",
                borderColor: "#5664d2",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#5664d2",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#5664d2",
                pointHoverBorderColor: "#fff",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: data
              }
            ]
          },
          {
            defaultFontColor: "#8791af",
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)"
                  }
                }
              ],
              yAxes: [
                {
                  ticks: {
                    max: max,
                    min: min,
                    stepSize: 1000
                  },
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)"
                  }
                }
              ]
            }
          }
      )
    }
  },

  mounted() {

    this.getData1()

  }
};
</script>